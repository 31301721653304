












import Vue from 'vue'
import * as echarts from 'echarts'
import cyFormat from '@/utils/cyFormat'
import EchartsDaily from './EchartsDaily'
export default Vue.extend({
  props: {
    data: { type: Object },
    isSkycon: { type: Boolean, default: false },
  },
  data() {
    return {
      daysData: [],
    }
  },
  mounted() {
    this.echarts()
  },
  methods: {
    echarts() {
      const dom: any = document.getElementById('echartsDay')
      const myChart: any = echarts.init(dom) // 基于准备好的dom，初始化echarts实例
      window.onresize = myChart.resize;  // 图表宽度自适应
      // console.log(this.data)
      const { temperature, precipitation, skycon } = this.data
      const daysData = []
      skycon.map((item, index) => {
        daysData.push({
          date: item.date.split('T')[0].split('-').slice(1, 3).join('/'),
          skyconText: cyFormat.getSkyconDesc(item.value),
          imgUrl: `/static/weatherIcon/${item.value}.png`,
        })
      })
      this.daysData = daysData
      // console.log(daysData)
      myChart.setOption(EchartsDaily(temperature, precipitation, this.isSkycon ? skycon : null))
    },
  }
})
