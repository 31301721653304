








































































































import Vue from 'vue'
import moment from 'moment'
import Daily from '@/views/data/weather/daily.vue'
import { videoArray } from './video'
import windElement from '@/utils/windElement'

import request from '@/utils/request'
export default Vue.extend({
  components: {
    Daily,
  },
  data() {
    return {
      index: 0,
      videoArray,
      data: {},
      cimissData: {},
    }
  },
  watch: {
    index() {
      this.init()
    },
  },
  computed: {
    current(): any {
      return this.$store.state.current
    },
    playerOptions(): any {
      return {
        autoplay: true, //如果true,浏览器准备好时开始回放。
        muted: true, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: "application/x-mpegURL",
          src: videoArray[this.index].url //你的m3u8地址（必填）
        }],
        poster: "poster.jpg", //你的封面地址
        width: document.documentElement.clientWidth,
        notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
      }
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.getCimissData()
      this.getDailyData()
    },
    getCimissData() {
      const { nearStationId } = videoArray[this.index]
      const params = {
        staIds: nearStationId,
        time_range: `${moment(new Date()).format('YYYYMMDDHH0000')},${moment(new Date()).format('YYYYMMDDHH0000')}`
        // elements: 'datetime,pre_1h,tem,rhu',
      }
      request.get('/yps_station_data/hour_data/list', params)
        .then(res => {
          console.log(res)
          if (!res || res.length == 0) return
          const data = res.filter((e: any) => e.station_id == nearStationId)
          this.cimissData = data[0]
        })
    },
    getDailyData() {
      const { lon, lat } = videoArray[this.index]
      request.get(`/v2.5/realtime?point=${lon},${lat}`)
        // request.get(`/v2.5/0woAfhHWQi4n5X9N/${lon},${lat}/daily.json?dailysteps=4`)
        .then(res => this.data = res)
    },
    register() {
      this.$router.replace('/register')
    },
    windDirection(value: number) {
      return windElement.windDirection(value)
    }
  },
  filters: {
    dtFormat(datetime: number) {
      const time = moment(datetime, 'YYYYMMDDHHmmss')
      return time.add(8, 'hours').format('YYYY年M月D日HH时')
    }
  }
})
